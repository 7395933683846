export const Shopify = () => import('./Shopify.vue');
export const Custom = () => import('./InsertCode.vue');
export const Other = () => import('./InsertCode.vue');
export const Wordpress = () => import('./Wordpress.vue');
export const Woocommerce = () => import('./Wordpress.vue');
export const Bigcommerce = () => import('./Bigcommerce.vue');
export const Tagmanager = () => import('./Tagmanager.vue');
export const Unas = () => import('./Unas.vue');
export const Shoprenter = () => import('./Shoprenter.vue');
export const Magento = () => import('./Magento.vue');
