import { mapGetters } from 'vuex';
import { purifyDomain } from '@/util';

const ONE_DAY = 1000 * 60 * 60 * 24;

export default {
  data: () => ({
    url: {
      protocol: '',
      domain: '',
      path: '',
      gotUrl: '',
    },
  }),
  computed: {
    ...mapGetters(['domains']),
    savedDomain() {
      return this.domains.find(({ domain }) => {
        const pureDomain = purifyDomain(this.url?.domain);
        return (
          domain === pureDomain || domain === this.url?.domain || `www.${pureDomain}` === domain
        );
      });
    },
  },
  methods: {
    hasRequestRecently(domain) {
      if (!domain) return null;

      const lrDate = new Date(domain.lastRequestDate).getTime();
      const lrDateV3 = new Date(domain.v3LastRequestDate)?.getTime();
      const now = new Date().getTime();
      const diffLrDate = (now - lrDate) / ONE_DAY;
      const diffLrDateV3 = (now - lrDateV3) / ONE_DAY;

      return diffLrDate < 1 || diffLrDateV3 < 1;
    },
    setQueryStringParameter(name, value) {
      const params = new URLSearchParams(window.location.search);

      if (value) {
        params.set(name, value);
      } else {
        params.delete(name);
      }

      window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
    },
    setCampaignDomain(input) {
      if (!input) return;
      this.url.gotUrl = input;

      const rawUrl = /^(https?:\/\/|\/\/)/.test(input) ? input : `http://${input}`;
      const url = new URL(rawUrl);
      this.url.domain = url.hostname;
      this.url.protocol = url.protocol;
      this.url.path = url.pathname;
    },
  },
};
